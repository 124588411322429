import { Image } from "antd";
import {
  Facebook,
  Twitter,
  Youtube,
  Linkedin,
  Instagram,
  Phone,
  Mail,
  MapPin,
} from "lucide-react";
import { Link } from "react-router-dom";
import logo from "../../src/assets/images/logo/logo_ivy.png";
// AppleStoreLogo
import AppleStoreLogo from "./AppleStoreLogo";
import CHplayLogo from "./CHplayLogo";

import QRAppStore from "../assets/images/Appstore.png";
import QRCHplay from "../assets/images/CHplay.png";

const Footer = () => (
  <footer className="mt-20 bg-gradient-to-b from-sky-50 to-white">
    <div className="container px-4 py-12 mx-auto">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
        {/* Company Info */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-sky-900">IVY Health</h3>
          <p className="text-sm text-muted-foreground">
            Chăm sóc sức khỏe chuyên nghiệp với các dịch vụ y tế chất lượng cao
          </p>
          <div className="flex space-x-4">
            <Link href="#" className="text-sky-600 hover:text-sky-700">
              <Facebook className="w-5 h-5" />
              <span className="sr-only">Facebook</span>
            </Link>
            <Link href="#" className="text-sky-600 hover:text-sky-700">
              <Twitter className="w-5 h-5" />
              <span className="sr-only">Twitter</span>
            </Link>
            <Link href="#" className="text-sky-600 hover:text-sky-700">
              <Instagram className="w-5 h-5" />
              <span className="sr-only">Instagram</span>
            </Link>
          </div>
        </div>

        {/* Quick Links */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-sky-900">Liên Kết Nhanh</h3>
          <ul className="space-y-2 text-sm">
            <li>
              <Link
                href="#"
                className="text-muted-foreground hover:text-sky-600"
              >
                Về IVY Health
              </Link>
            </li>
            <li>
              <Link
                href="#"
                className="text-muted-foreground hover:text-sky-600"
              >
                Xét Nghiệm
              </Link>
            </li>
            <li>
              <Link
                href="#"
                className="text-muted-foreground hover:text-sky-600"
              >
                Kết Quả Xét Nghiệm
              </Link>
            </li>
            <li>
              <Link
                href="#"
                className="text-muted-foreground hover:text-sky-600"
              >
                Hỗ Trợ
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-sky-900">Liên Hệ</h3>
          <ul className="space-y-2 text-sm">
            <li className="flex items-center space-x-2 text-muted-foreground">
              <Phone className="w-4 h-4" />
              <span>0908.710.710</span>
            </li>
            <li className="flex items-center space-x-2 text-muted-foreground">
              <Mail className="w-4 h-4" />
              <span>support@ivyhealth.com</span>
            </li>
            <li className="flex items-center space-x-2 text-muted-foreground">
              <MapPin className="w-4 h-4" />
              <span>TP. Hồ Chí Minh, Việt Nam</span>
            </li>
          </ul>
        </div>

        {/* App Downloads with QR Codes */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-sky-900">Tải Ứng Dụng</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col items-center space-y-2">
              <img
                src={QRCHplay}
                alt="QR Code for Google Play"
                className="w-24 h-24"
              />
              <a
                href="https://play.google.com/store/apps/details?id=com.boot.mdl315&pli=1"
                className="block transition-transform hover:scale-105"
              >
                <CHplayLogo />
              </a>
            </div>
            <div className="flex flex-col items-center space-y-2">
              <img
                src={QRAppStore}
                alt="QR Code for App Store"
                className="w-24 h-24"
              />
              <a
                href="https://apps.apple.com/us/app/medical-315/id6502935225"
                className="block transition-transform hover:scale-105"
              >
                <AppleStoreLogo />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="pt-8 mt-8 border-t">
        <p className="text-sm text-center text-muted-foreground">
          © 2024 IVY Health. Tất cả quyền được bảo lưu.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
